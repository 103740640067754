import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Hoehenhaus: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Hoehenhaus,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <polygon
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Höhenhaus"
          points="778,440 786,472 720,490 683,438 676,404 714,396 724,414 744,402 772,418"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 704.1467 446.7232)"
        >
          Höhenhaus
        </text>
      </g>
    </>
  );
});
